import React, { useState } from 'react'
import { ReactComponent as FaceX } from '../../assets/svg/faceX.svg'
import { ReactComponent as Tele } from '../../assets/svg/telegram.svg'
import { ReactComponent as Copy } from '../../assets/svg/copyicon.svg'
import { ReactComponent as TickIcon } from '../../assets/svg/tick.svg'
import { ADDRESS_WALLET, TELEGRAM, WHATAPP, FACEBOOK, UNISWAP } from '../../constants/index'



const Wolf = () => {
  const [isCopy, setIsCopy] = useState(false)
  const handleCopy = () => {
    if (isCopy) return
    setIsCopy(true)
    navigator.clipboard.writeText(ADDRESS_WALLET)
    alert("Copy address successful!")
    setTimeout(() => {
      setIsCopy(false)
    }, 2500)
    
  }
  return (
    <div className="wolf">
      <div className="wolf-content">
        <button data-text="Solar-wolf" className="button-solar">
          <span className="actual-text">&nbsp;BASE Wolf&nbsp;</span>
          <span className="hover-text" aria-hidden="true">&nbsp;Ocean Legned: Sola Wolf&nbsp;</span>
        </button>
        
        <div className="wolf-center">
          <div className="wolf-buy">
            <img src="/images/wolf/buy-1.png" alt="img-frame2" />
          </div>
          <div className='btn-social'>
            {/* <p>0x6bdB939815164d9107aEBaBA0EF...</p> */}
            <p>{ADDRESS_WALLET}</p>
            <button className='svg-copy' onClick={handleCopy}>
              {isCopy ? <TickIcon /> : <Copy />}
            </button>
            <a target="blank" href={UNISWAP} className='btn-pinksale'>
             <h3 className='pinksale-left'>Trade {} on Uniswap</h3>
              <img src="/images/wolf/pwc1.png" alt="icon-pinksale-right" className='pinksale-right' />
            </a>
          </div>
          <div className='social-svg'>
           
            <a href={TELEGRAM} className='btn-svg'> <Tele /></a>
            <a href={FACEBOOK} className='btn-svg'> <FaceX /> </a>
            <a href={WHATAPP} className='btn-svg'>  <img src='images/wolf/care2.svg' alt='care'/></a>
            

          </div>
        
        </div>
        <div className='wolf-img'>
          <img data-aos="fade-down" src="/images/wolf/soi.png" alt="wolf-img" />
        </div>
      </div>
        
      <div className='text-section1'>
        <b className='sec1'>Section 1 </b>
        
        <br />
        <p>Section 1 is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining </p>
        <br />
        <p>essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      </div>

      <div className='text-section2'>
        <b className='sec1'>Section 2</b>
        
        <br />
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining </p>
        <br />
        <p>essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      </div>

      <div className='text-url'>
         <h2>To be continued</h2>
      </div>
    </div>
    
  );
};

export default Wolf;
