
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'


import LandingPage from './LandingPage'


const DefaultLayout = () => {

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default DefaultLayout
