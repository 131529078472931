import Collection from '../components/Collection'
import Clients from '../components/Clients'
import SolaWolf from '../components/SolaWolf'
import React from 'react'
import Disclaimer from '../components/Disclaimer'
import WhySola from '../components/WhySola' 


const LandingPage = () => {

  return (
    <div className='landing-page'>
      <SolaWolf/>
      <WhySola/>
      <Collection/>
      <Clients/>
      <Disclaimer/>
    </div>
  )
}
export default LandingPage
