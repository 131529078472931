import React from "react";
import { ReactComponent as Icon9 } from "../../assets/svg/audit.svg";
import { ReactComponent as Icon10 } from "../../assets/svg/gotbit2.svg";
import { ReactComponent as Icon11 } from "../../assets/svg/partner.svg";
import { ReactComponent as Icon12 } from "../../assets/svg/exp.svg";
import { ReactComponent as Icon13 } from "../../assets/svg/marketing.svg";
import { ReactComponent as Icon14 } from "../../assets/svg/buyback.svg";
import { ReactComponent as Icon15 } from "../../assets/svg/cex.svg";
import { ReactComponent as Icon16 } from "../../assets/svg/game.svg";
import { ReactComponent as Icon17 } from "../../assets/svg/bsc.svg";
import { ReactComponent as Icon18 } from "../../assets/svg/nft.svg";
import { ReactComponent as Icon19 } from "../../assets/svg/burn.svg";
import { ReactComponent as Icon20 } from "../../assets/svg/contest.svg";

const index = () => {
  return (
    <div>
      <div className="bonkenomics">
        <h2>BASEWOLF TOKENOMICS</h2>
        <div className="score">
          <div className="certik">
            <span>AUDIT</span>
            <br />
            <b>SECURITY SCORE</b>

            <div className="bar"></div>
            <div className="score-value">80%</div>
          </div>
        </div>

        <div className="container-stats">
          <div className="col-4">
            <span>
              5% BUY & <br /> SELL TAX
            </span>
          </div>
          <div className="col-4">
            <span>4200</span> <br /> <span>TOTAL SUPPLY</span>
          </div>
          <div className="col-4">
            <span>
              LOCKED <br /> LIQUIDITY
            </span>
          </div>
        </div>
      </div>
      <div className="where-solar">
        <h2>WHY SOLAR WOLF?</h2>
        <div className="container-solars">
          <div className="col-3">
            <Icon9 />
            <p>audited</p>
          </div>
          <div className="col-3">
            <Icon10 />
            <p>gotbit mm</p>
          </div>
          <div className="col-3">
            <Icon11 />
            <p>+100 partnerships</p>
          </div>
          <div className="col-3">
            <Icon12 />
            <p>experienced team</p>
          </div>
        </div>

        <div className="container-solars">
          <div className="col-3">
            <Icon13 />
            <p>daily marketing</p>
          </div>
          <div className="col-3">
            <Icon14 />
            <p>daily buybacks</p>
          </div>
          <div className="col-3">
            <Icon15 />
            <p>5 cex listings</p>
          </div>
          <div className="col-3">
            <Icon16 />
            <p>p2e game</p>
          </div>
        </div>
        <div className="container-solars">
          <div className="col-3">
            <Icon17 />
            <p>bsc’s biggest meme</p>
          </div>
          <div className="col-3">
            <Icon18 />
            <p>nfts</p>
          </div>
          <div className="col-3">
            <Icon19 />
            <p>regular burns</p>
          </div>
          <div className="col-3">
            <Icon20 />
            <p>weekly contests</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
