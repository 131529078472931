
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow ,Navigation } from 'swiper/modules';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ReactComponent as Prev } from '../../assets/svg/btn-prev.svg'
import { ReactComponent as Next } from '../../assets/svg/btn-next.svg'

const Collection = () => {
  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)
  return (
    <div className="collection">
      <img src="/images/wolf/our-client2.png" alt="our-client2" className='img-client2' />
      <div className='retangle-title1'>
        <div className='text-on-image'>
          <p> Collection</p>
        </div>
        <img src="/images/wolf/layer2.png" alt="img-layer1" className='img-layer1' />
        {/* <img src="/images/wolf/Rectangle147.png" alt="img-layer2" className='img-layer2' /> */}

      </div>

      <br />
      <div className='tittle-group' id='tittle-1'>
        You can learn more about our token on those websites. As we expand, there will be more and more partners.
      </div>
      <br></br>
      <br />
      <div className="slide-collection">

      </div>
      <div className="slide-show">
        <div className="slide-show-collection">
          <Swiper
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 20, // trượt theo độ
              stretch: 0, // khoảng cách giữa các slide
              depth: 200, // chiều sâu
              modifier: 1, // hệ số hiệu ứng
              // slideShadows:true,
            }}
            pagination={{el:'.swiper-pagination' ,clickable:true}}
            navigation= {{
              nextEl:'.custom-next-button',
              prevEl:'.custom-prev-button',
              clickable:true
            }}
            modules={[EffectCoverflow, Pagination , Navigation]}
            className="mySwiper"
            initialSlide={3} // Set the index of the initial active slide
            keyboard={{
              enabled: true,
            }}
          >
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img1.png') no-repeat 50% 50% / cover`,         
              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img2.png') no-repeat 50% 50% / cover`,

              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img3.png') no-repeat 50% 50% / cover`,

              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img4.png') no-repeat 50% 50% / cover`,

              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img5.png') no-repeat 50% 50% / cover`,

              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img6.png') no-repeat 50% 50% / cover`,

              }}
            />
            <SwiperSlide
              className="swiper-slide"
              style={{
                background: `url('/images/collection/img7.png') no-repeat 50% 50% / cover`,

              }}
            />
          </Swiper>
        </div>
      </div>

      <div className="btn-prev-next-group">
        <div className='swiper-pagination'></div>

        <div className="custom-prev-button swiper-button-prev slider-arrow ">
          <Prev/>  
          
        </div>

        <div className="custom-next-button swiper-button-next slider-arrow ">
          <Next/> 
          
        </div>
        
      </div>
      <br />
      <br />
      <div className="tittle-group" id="tittle-2">
        You can learn more about our token on those websites. As we expand, there will be more and more partners.
      </div>


    </div>
  );
};

export default Collection;
