import React from 'react'
import { ReactComponent as Icon1 } from '../../assets/svg/gateio.svg'
import { ReactComponent as Icon2 } from '../../assets/svg/bitget.svg'
import { ReactComponent as Icon3 } from '../../assets/svg/mexc.svg'
import { ReactComponent as Icon4 } from '../../assets/svg/bitmart.svg'
import { ReactComponent as Icon5 } from '../../assets/svg/cmc.svg'
import { ReactComponent as Icon6 } from '../../assets/svg/cg.svg'
import { ReactComponent as Icon7 } from '../../assets/svg/gotbit.svg'
import { ReactComponent as Icon8 } from '../../assets/svg/flooz.svg'



const Client = () => {
  return (
    <div className="our-clients">

      <img src="/images/wolf/our-client2.png" alt="our-client2" className='img-client2' />
      <div className='retangle-title1'>
        <div className='text-on-image'>
          <p> Our Partners</p>
        </div>
        <img src="/images/wolf/layer2.png" alt="img-layer1" className='img-layer1' />


      </div>
      <br />
      <div className="tittle-group" id='tittle-0'>
        You can learn more about our token on those websites. As we expand, there will be more and more partners.
      </div>

      <div className='clients-group'>
        <div className='clients-row'>
          <Icon1 className='item-svg' />
          <Icon2 className='item-svg'/>
          <Icon3 className='item-svg'/>
          <Icon4 className='item-svg'/>
          <Icon5 className='item-svg'/>
          <Icon6 className='item-svg'/>
          <Icon7 className='item-svg'/>
          <Icon8 className='item-svg'/>
        </div>
        
      </div>
      <div className='btn-view-story'>
        <p>View customere stories  →</p>
      </div>

      {/* <div className='bonkenomics'>
        <h2>BONKENOMICS</h2>
        <div class="score">
          <div className='certik'>
            <span>CERTIK</span>
            <br />
            <b>SECURITY SCORE</b>
            <div className='bar'></div>
            <div class="score-value">80%</div>
          </div>
        </div>

        <div class="container-stats">
          <div class="col-4">
            <span>5% BUY & <br /> SELL TAX</span>
          </div>
          <div class="col-4">
            <span>4200</span> <br /> TOTAL SUPPLY
          </div>
          <div class="col-4">
            <span>LOCKED <br /> LIQUIDITY</span>
          </div>
        </div>
      </div> */}

      {/* <div className='where-solar'>
        <h2>WHERE SOLAR WOLF?</h2>
        <div className='container-solars'>
          <div className='col-3'>
            <Icon9 />
            <p>audited by certik</p>
          </div>
          <div className='col-3'>
            <Icon10 />
            <p>gotbit mm</p>
          </div>
          <div className='col-3'>
            <Icon11 />
            <p>+100 partnerships</p>
          </div>
          <div className='col-3'>
            <Icon12 />
            <p>experienced team</p>
          </div>
        </div>

        <div className='container-solars'>
          <div className='col-3'>
            <Icon13 />
            <p>daily marketing</p>
          </div>
          <div className='col-3'>
            <Icon14 />
            <p>daily buybacks</p>
          </div>
          <div className='col-3'>
            <Icon15 />
            <p>5 cex listings</p>
          </div>
          <div className='col-3'>
            <Icon16 />
            <p>p2e game</p>
          </div>
        </div>
        <div className='container-solars'>
          <div className='col-3'>
            <Icon17 />
            <p>bsc’s biggest meme</p>
          </div>
          <div className='col-3'>
            <Icon18 />
            <p>nfts</p>
          </div>
          <div className='col-3'>
            <Icon19 />
            <p>regular burns</p>
          </div>
          <div className='col-3'>
            <Icon20 />
            <p>weekly contests</p>
          </div>
        </div>

      </div> */}
    </div>
  );
};

export default Client;
