


import React from 'react';

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <div className="disclaimer-cont">
        <div className="disclaimer-half disclaimer-left">
          <img src="/images/disclaimer/wolf2.png" alt="lunar" className='img-wolf1' />
        </div>
        <div className="disclaimer-half disclaimer-center">
          <div className='disclaimer-content'>
          <h2>DISCLAIMERS</h2>
          <div className="tittle-footer">
            <b>$LD404</b>  is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or
            roadmap. The coin is for entertainment purposes only.
          </div>
          </div>

        </div>
        <div className="disclaimer-half disclaimer-right">
          <img src="/images/disclaimer/layer2.png" alt="lunar" className='img-wolf1' />
        </div>
      </div>
      
    </div>
  );
};

export default Disclaimer;
